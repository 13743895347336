import React, { useEffect, useState } from "react";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import SignIn from "./pages/sign-in/SignIn";
import Dashboard from "./pages/dashboard/Dashboard";
import LeaveManagement from "./pages/leaveManagement/LeaveManagement";
import MedicalAllowance from "./pages/medicalAllowance/MedicalAllowance";
import LunchMangement from "./pages/lunchManagement/LunchManagement";
import AdvanceSalary from "./pages/advanceSalary/AdvanceSalary";
import WorkOnHoliday from "./pages/workOnHoliday/WorkOnHoliday";
import AssetRequisition from "./pages/assetRequisition/AssetRequisition";
import PaySlips from "./pages/paySlips/PaySlips";
import FuelAllowance from "./pages/fuelAllowance/FuelAllowance";
import EmployeeSettlement from "./pages/employeeSettlement/EmployeeSettlement";
import EmployeeManagement from "./pages/employeeManagement/EmployeeManagement";
import { setAuthUser, setCurrency } from "./features/Auth Slice/authSlice";
import spinnerSvc from "./utils/spinner-service";
import LoaderSpinner from "./components/Loader/Loader";
import { storageKeys } from "./constants/storage.constants";
import { useSelector } from "react-redux";
import { apiBaseUrl } from "./constants/environment.constants";
import { notificationSocket } from "./utils/notifications.socket.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MsalProvider } from "@azure/msal-react";
import Profile from "./pages/AdminProfile/Profile";
import { PublicClientApplication } from "@azure/msal-browser";
import PerformanceMangement from "./pages/performanceManagement/PerformanceMangement";
import EmpReports from "./pages/EmpReports/EmpReports";
import CertificatesAndLetters from "./pages/CertificatesAndLetters/CertificatesAndLetters";
import { RootState, store } from "./store/store";
import Suggestion from "./pages/suggestion/Suggestion";
import DetailScreen from "./pages/Detail/DetailScreen";
import Schooling from "./pages/Schooling/Schooling";
import BonusScreen from "./pages/Bonus/BonusScreen";
import SideBar from "./components/sideBar/SideBar";
import Header from "./components/header/Header";

interface Props {
  msalInstance?: PublicClientApplication;
}

const App: React.FC<Props> = ({ msalInstance }) => {
  const [loading, setLoading] = useState(null);
  const { User } = useSelector((state: RootState) => state?.auth);
  const { toastType, toastMsg } = useSelector(
    (state: RootState) => state.toast
  );

  const handleNotification = () => {
    notificationSocket?.socket?.on(
      "workOnHolidayReqCreated",
      (WOHRequested: any) => {
        console.log("WOH Requested: ", WOHRequested);
      }
    );
    notificationSocket?.socket?.on("leaveReqCreated", (leaveRequested: any) => {
      console.log("WOH Requested: ", leaveRequested);
    });
  };

  console.log(process.env);

  useEffect(() => {
    if (!toastMsg) return;
    toast(toastMsg, {
      //   position: "top-right",
      autoClose: 4000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      //   transition: Slide,
    });
  }, [toastMsg]);

  // useEffect(() => {
  //   if (!User?.id || !apiBaseUrl) return;
  //   notificationSocket?.initializeConnection(apiBaseUrl, User?.id);
  //   handleNotification();
  // }, [User]);

  useEffect(() => {
    const subscription = spinnerSvc.requestInProgress.subscribe((data: any) => {
      setLoading(data);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const userDetails =
    localStorage.getItem(storageKeys?.rememberMe) === "true"
      ? JSON.parse(localStorage.getItem(storageKeys?.user)!)
      : JSON.parse(localStorage.getItem(storageKeys?.user)!);
  const currency = localStorage.getItem("currency");
  useEffect(() => {
    if (User?.id && User?.role !== "admin") {
      toast.error("You don't have permission to login!");
      return;
    }
  }, [User?.id]);

  useEffect(() => {
    // if (!userDetails?.id || userDetails?.role !== "admin") return;
    store.dispatch(setAuthUser(userDetails));
    store.dispatch(setCurrency(currency));
  }, [userDetails?.id]);
  return (
    <>
      <MsalProvider instance={msalInstance as PublicClientApplication}>
        {loading && <LoaderSpinner />}
        <ToastContainer
          // position="top-right"
          autoClose={4000}
          // hideProgressBar={false}
          // newestOnTop={false}
          // closeOnClick
          // rtl={false}
          // pauseOnFocusLoss
          // draggable
          // pauseOnHover
          // theme="colored"
          // transition= Bounce
        />
        {userDetails?.id && userDetails?.role === "admin" ? (
          <Router>
            <Header />

            <div className="flex flex-row">
              <div className="xl:basis-[385px] xl:block hidden">
                <SideBar />
              </div>
              <Routes>
                {/* <Route path="/" element={<SignIn />} /> */}
                <Route path="/" element={<Dashboard />} />
                <Route
                  path="/employee-management"
                  element={<EmployeeManagement />}
                />
                <Route
                  path="/employee-performance"
                  element={<PerformanceMangement />}
                />
                <Route path="/leave-management" element={<LeaveManagement />} />
                <Route
                  path="/medical-allowance"
                  element={<MedicalAllowance />}
                />
                <Route path="/lunch-management" element={<LunchMangement />} />
                <Route path="/advance-salary" element={<AdvanceSalary />} />
                <Route
                  path="/asset-requisition"
                  element={<AssetRequisition />}
                />
                <Route path="/work-on-holiday" element={<WorkOnHoliday />} />
                <Route path="/payslips" element={<PaySlips />} />
                <Route path="/fuel-allowance" element={<FuelAllowance />} />
                <Route
                  path="/employee-settlement"
                  element={<EmployeeSettlement />}
                />
                <Route path="/emp-report" element={<EmpReports />} />
                <Route
                  path="/emp-certificates"
                  element={<CertificatesAndLetters />}
                />
                <Route path="/emp-suggestion" element={<Suggestion />} />
                <Route path="/emp-detail" element={<DetailScreen />} />
                <Route path="/emp-schooling" element={<Schooling />} />
                <Route path="/bonus" element={<BonusScreen />} />

                <Route path="/profile" element={<Profile />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </div>
          </Router>
        ) : (
          <Router>
            <Routes>
              <Route path="/sign-in" element={<SignIn />} />
              <Route path="*" element={<Navigate to="/sign-in" />} />
            </Routes>
          </Router>
        )}
      </MsalProvider>
    </>
  );
};

export default App;
